import React from "react";
import AOS from "aos";
import "./App.css";
import styled from "styled-components";
import "aos/dist/aos.css";
import MainPage from "./pages/v3/main";
import LeftSidebar from "./section/v3/left-sidebar";
import Video from "./section/v3/video";

const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  position: relative;
`;

class App extends React.Component {
  componentDidMount() {
    AOS.init({
      duration: 1000,
    });
  }

  render() {
    return (
      <Wrapper>
        <Video />
        <LeftSidebar />
        <MainPage />
      </Wrapper>
    );
  }
}

export default App;
