import React from "react";
import { CustomContainer, SectionWrapper } from "../introduction/style";
import { AboutWrapper } from "../about/style";
import { SkillCard, SkillSets, SkillWrapper } from "./style";
import * as Si from "react-icons/si";
import * as Tb from "react-icons/tb";
import * as Fa from "react-icons/fa";

const Advantages = (props) => (
  <SectionWrapper>
    <CustomContainer>
      <AboutWrapper>
        <h4 data-aos="fade-up">
          <Fa.FaShapes />
          Skills
        </h4>
        <h1 data-aos="fade-up">
          My <span style={{ color: "#28e98c" }}>Skills</span>
        </h1>
        <SkillSets>
          <SkillWrapper data-aos="fade-right">
            <SkillCard>
              <Tb.TbBrandReact color="#fff" size={55} />
              <h1>95%</h1>
              <span>Web Development</span>
            </SkillCard>
            <p>ReactJs</p>
          </SkillWrapper>
          <SkillWrapper data-aos="fade-up">
            <SkillCard>
              <Tb.TbBrandNextjs color="#fff" size={55} />
              <h1>50%</h1>
              <span>Web Development</span>
            </SkillCard>
            <p>NextJs</p>
          </SkillWrapper>
          <SkillWrapper data-aos="fade-bottom">
            <SkillCard>
              <Tb.TbBrandGolang color="#fff" size={55} />
              <h1>90%</h1>
              <span>Backend</span>
            </SkillCard>
            <p>Golang</p>
          </SkillWrapper>
          <SkillWrapper data-aos="fade-left">
            <SkillCard>
              <Tb.TbBrandFlutter color="#fff" size={55} />
              <h1>95%</h1>
              <span>Mobile Development</span>
            </SkillCard>
            <p>Flutter</p>
          </SkillWrapper>
          <SkillWrapper data-aos="fade-right">
            <SkillCard>
              <Si.SiApachekafka color="#fff" size={55} />
              <h1>70%</h1>
              <span>Data Streaming</span>
            </SkillCard>
            <p>Kafka</p>
          </SkillWrapper>
          <SkillWrapper data-aos="fade-up">
            <SkillCard>
              <Si.SiRedis color="#fff" size={55} />
              <h1>85%</h1>
              <span>Database, Cache</span>
            </SkillCard>
            <p>Redis</p>
          </SkillWrapper>
          <SkillWrapper data-aos="fade-bottom">
            <SkillCard>
              <Si.SiPostgresql color="#fff" size={55} />
              <h1>90%</h1>
              <span>Database, Relational</span>
            </SkillCard>
            <p>PostgreSQL</p>
          </SkillWrapper>
          <SkillWrapper data-aos="fade-left">
            <SkillCard>
              <Si.SiMysql color="#fff" size={55} />
              <h1>90%</h1>
              <span>Database, Relational</span>
            </SkillCard>
            <p>MySQL</p>
          </SkillWrapper>
          <SkillWrapper data-aos="fade-right">
            <SkillCard>
              <Si.SiArangodb color="#fff" size={55} />
              <h1>70%</h1>
              <span>Database, NoSQL</span>
            </SkillCard>
            <p>ArangoDB</p>
          </SkillWrapper>
          <SkillWrapper data-aos="fade-up">
            <SkillCard>
              <Si.SiHeroku color="#fff" size={55} />
              <h1>100%</h1>
              <span>Cloud</span>
            </SkillCard>
            <p>Heroku</p>
          </SkillWrapper>
          <SkillWrapper data-aos="fade-bottom">
            <SkillCard>
              <Si.SiGooglecloud color="#fff" size={55} />
              <h1>50%</h1>
              <span>Cloud</span>
            </SkillCard>
            <p>Google Cloud</p>
          </SkillWrapper>
        </SkillSets>
      </AboutWrapper>
    </CustomContainer>
  </SectionWrapper>
);

export default Advantages;
