import React from "react";
import * as Tb from "react-icons/tb";
import img04 from "../../../assets/images/v3/courses/free.png";
import img02 from "../../../assets/images/v3/courses/shopee.png";
import img03 from "../../../assets/images/v3/courses/spotify.png";
import { AboutWrapper } from "../about/style";
import { CustomContainer, SectionWrapper } from "../introduction/style";
import { BottomCourse, CoursesGrid, CoursesItem, JoinButton } from "./style";

const Courses = (props) => (
  <SectionWrapper id="courses">
    <CustomContainer>
      <AboutWrapper>
        <h4 data-aos="fade-up">
          <Tb.TbBook />
          Courses
        </h4>
        <h1 data-aos="fade-up">
          Latest <span style={{ color: "#28e98c" }}>Products</span>
        </h1>
        <CoursesGrid>
          <CoursesItem data-aos="fade-bottom">
            <img src={img02} alt="" />
            <h1>Basic Web Slicing</h1>
            <p>
              We'll learn the basic of Web Development starts from HTML5, CSS3, and apply it to Shopee UI Apps. Sounds interesting?
            </p>
            <BottomCourse>
              <JoinButton
                onClick={() =>
                  window.open("https://forms.gle/WjcAxSeGmKRBxXju6")
                }
              >
                Join
              </JoinButton>
              <h1>
                IDR{" "}
                <span>
                  <s>129.000</s> 99.000
                </span>
              </h1>
            </BottomCourse>
          </CoursesItem>

          <CoursesItem data-aos="fade-bottom">
            <img src={img03} alt="" />
            <h1>UI Source Code</h1>
            <p style={{ flex: "1" }}>
              Buy UI Source Code to get the Code immediately. From Spotify, Twitter, Grab, Shopee, Traveloka, Blibli, Youtube to AirBNB.
            </p>
            <BottomCourse>
              <JoinButton
                onClick={() =>
                  window.open("https://forms.gle/WjcAxSeGmKRBxXju6")
                }
              >
                Buy
              </JoinButton>
            </BottomCourse>
          </CoursesItem>
          <CoursesItem data-aos="fade-bottom">
            <img src={img04} alt="" />
            <h1>Free Whatsapp & Spotify UI</h1>
            <p style={{ flex: "1" }}>
              Request for Free Whatsapp & Spotify UI Slicing Source Code
            </p>
            <BottomCourse>
              <JoinButton
                onClick={() =>
                  window.open("https://t.me/+OzLylXHPWT5jM2Fl")
                }
              >
                Request
              </JoinButton>
            </BottomCourse>
          </CoursesItem>
        </CoursesGrid>
      </AboutWrapper>
    </CustomContainer>
  </SectionWrapper>
);

export default Courses;
