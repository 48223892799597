import React from "react";
import { CustomContainer, SectionWrapper } from "../introduction/style";
import { AboutWrapper } from "../about/style";
import { ResumeItem, ResumeTimeline } from "./style";
import { ExperienceData } from "./data";
import * as Cg from "react-icons/cg";

const Experience = () => (
  <SectionWrapper>
    <CustomContainer>
      <AboutWrapper>
        <h4 data-aos="fade-up">
          <Cg.CgBriefcase />
          Resume
        </h4>
        <h1 data-aos="fade-up">
          Education & <span style={{ color: "#28e98c" }}>Experience</span>
        </h1>
        <ResumeTimeline>
          {ExperienceData.map((experience, index) => (
            <ResumeItem
              className="resume_item"
              style={{ paddingBottom: experience["isEnd"] ? "0px" : "30px" }}
              key={index}
              data-aos="fade-left"
            >
              <span>{experience["date"]}</span>
              {experience["experiences"].map((exp, idx) => (
                <div key={idx}>
                  <h2>{exp["title"]}</h2>
                  <a href={exp["href"]} target="_blank" rel="noreferrer">
                    {exp["company"]}
                  </a>
                </div>
              ))}
            </ResumeItem>
          ))}
        </ResumeTimeline>
      </AboutWrapper>
    </CustomContainer>
  </SectionWrapper>
);

export default Experience;
