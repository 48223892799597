export const PricingData = [
  {
    title: "Basic",
    desc: `Have design ready to build? \n or small budget`,
    amount: "$10",
    features: [
      "Need your design",
      "Implement with ReactJs",
      "Remote/Online",
      "Work in weekdays, no weekend.",
      "Support 6 months",
      "Another $5/hr for additional backend",
      "Server pay on your own",
    ],
    aos: "fade-right",
  },
  {
    title: "Premium",
    desc: `Not have any design?\nLeave it to me`,
    amount: "$25",
    features: [
      "Don't need wireframe or anything",
      "Implement with ReactJs",
      "Remote/Online",
      "Support 12 months",
      "Your project always be priority",
      "Another $6/hr for additional backend",
      "Server pay on your own",
    ],
    aos: "fade-left",
  },
];
