import React, { Component } from "react";
import { FormContainer, FormWrapper, InputGroup } from "./style";
import { PricingItem } from "../../../../section/v3/pricing/style";
import { HireMeButton } from "../../../../section/v3/left-sidebar/style";

class Work extends Component {
  render() {
    return (
      <FormWrapper data-aos="fade-up">
        <FormContainer>
          <PricingItem>
            <InputGroup>
              <label htmlFor="name">
                Full Name <sup>*</sup>
              </label>
              <input type="text" id="name" placeholder="Your Full Name" />
            </InputGroup>
          </PricingItem>
          <PricingItem>
            <InputGroup>
              <label htmlFor="email">
                Email <sup>*</sup>
              </label>
              <input type="text" id="email" placeholder="Your Email Address" />
            </InputGroup>
          </PricingItem>
          <PricingItem>
            <InputGroup>
              <label htmlFor="phone">
                Phone <sup>*</sup>
              </label>
              <input type="text" id="phone" placeholder="Your Phone Number" />
            </InputGroup>
          </PricingItem>
          <PricingItem>
            <InputGroup>
              <label htmlFor="subject">
                Subject <sup>*</sup>
              </label>
              <select name="subject" id="subject">
                <option value>Select a Subject</option>
                <option value="Web Development">Web Development</option>
                <option value="Mobile Development">Mobile Development</option>
                <option value="Tutorial">Tutorial</option>
              </select>
            </InputGroup>
          </PricingItem>
          <PricingItem style={{ width: "100%" }}>
            <InputGroup>
              <label htmlFor="budget">
                Budget <sup>*</sup>
              </label>
              <input
                type="number"
                id="budget"
                name="budget"
                placeholder="A range budget for your project"
              />
            </InputGroup>
          </PricingItem>
          <PricingItem style={{ width: "100%" }}>
            <InputGroup>
              <label htmlFor="message">
                Message <sup>*</sup>
              </label>
              <textarea
                name="message"
                id="message"
                cols="30"
                rows="10"
                placeholder="Write your message here"
              />
            </InputGroup>
          </PricingItem>

          <InputGroup>
            <HireMeButton>Send Message</HireMeButton>
          </InputGroup>
        </FormContainer>
      </FormWrapper>
    );
  }
}

export default Work;
