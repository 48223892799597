import styled from "styled-components";

export const ResumeTimeline = styled.div``;

export const ResumeItem = styled.div`
  padding-left: 74px;
  padding-bottom: 30px;
  position: relative;

  & > span {
    display: block;
    margin-bottom: 28px;
    color: #999;
    cursor: pointer;
  }

  & > span:hover {
    color: #28e98c;
  }

  &::before + &span:hover {
    background-color: #28e98c !important;
  }

  & > div > h2 {
    font-size: 24px;
    margin-bottom: 8px;
    color: #fff;
    font-weight: 300;
  }

  & > div > a {
    font-size: 13px;
    color: #999;
    margin-bottom: 18px;
    text-decoration: none;
    transition: all 0.5s ease 0s;
    display: block;

    &:hover {
      color: #28e98c;
      text-decoration: underline;
      transform: translate(0, -2px);
    }
  }

  &::before {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #656565;
    border-radius: 50%;
    left: -6px;
    top: 4px;
    transition: 0.3s;
  }

  &::after {
    content: "";
    background-color: #333333;
    width: 1px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 10px;
    z-index: -1;
  }
`;
