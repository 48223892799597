import styled from "styled-components";

export const SkillSets = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  row-gap: 50px;

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
`;

export const SkillWrapper = styled.div`
  flex: 0 0 auto;
  text-align: center;
  padding: 0 12px;

  & > p {
    font-size: 14px;
    color: #fff;
  }
`;

export const SkillCard = styled.div`
  border: 2px solid #565656;
  border-radius: 85px;
  padding: 54px 0 48px 0;
  margin-bottom: 20px;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    border-color: #28e98c;
  }

  & > h1 {
    font-size: 30px;
    color: #28e98c;
    font-weight: 300;
    margin-top: 30px;
  }

  & > span {
    color: #999999;
    font-size: 14px;
    margin: 8px 0;
    line-height: 1.4;
    display: inline-block;
  }
`;
