import styled from "styled-components";

export const PricingItems = styled.div``;

export const PricingFlex = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  @media screen and (min-width: 1024px) {
    gap: 16px;
    flex-direction: row;

    & > div {
      width: 50%;
    }
  }
`;

export const PricingItem = styled.div`
  flex: 0 0 auto;
`;

export const PricingTable = styled.div`
  border: 1px solid #565656;
  border-radius: 30px;
  padding: 37px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  &:hover {
    border-color: #28e98c;
  }

  & > a {
    background-color: #28e98c;
    color: #000;
    padding: 11px 58px 10px 58px;
    transition: 0.3s;
    cursor: pointer;
    border: 2px solid #28e98c;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-radius: 30px;
    text-transform: uppercase;

    &:hover {
      border-color: #28e98c;
      background-color: transparent;
      color: #28e98c;
    }
  }
`;

export const PricingHeader = styled.div`
  border-bottom: 1px solid #555555;
  padding-bottom: 20px;
  margin-bottom: 34px;

  & > h1 {
    font-size: 48px;
    color: #28e98c;
    font-weight: 300;

    & > span {
      color: #999999;
      font-size: 24px;
    }
  }
`;

export const PricingTop = styled.div`
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;

  & > h4 {
    font-size: 18px;
    font-weight: 300;
    color: #fff;
    text-transform: uppercase;
  }

  & > p {
    color: #999999;
    font-size: 13px;
    line-height: 20px;
    text-align: right;
    white-space: pre-wrap;
  }
`;

export const PricingFeatures = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 80px;

  & > span {
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    color: #fff;
  }
`;

export const PricingFooter = styled.p`
  margin-top: 60px;
  color: #fff !important;
  font-size: 14px !important;
  line-height: 24px;

  & > a {
    color: #28e98c;
  }
`;
