import React from "react";
import { CustomContainer, SectionWrapper } from "../introduction/style";
import { AboutWrapper } from "../about/style";
import {
  ProjectInner,
  ProjectItem,
  ProjectItemFlex,
  ProjectList,
  Tags,
  TagsWrapper,
} from "./style";
import * as Fa from "react-icons/fa";
import proj1 from "../../../assets/images/projects/1.png";
import proj2 from "../../../assets/images/projects/2.png";
import proj3 from "../../../assets/images/projects/3.png";
import proj4 from "../../../assets/images/projects/4.png";
import proj5 from "../../../assets/images/projects/5.png";
import proj6 from "../../../assets/images/projects/6.png";
import proj7 from "../../../assets/images/projects/7.png";

const Projects = (props) => (
  <SectionWrapper id="project">
    <CustomContainer>
      <AboutWrapper>
        <h4 data-aos="fade-up">
          <Fa.FaGripVertical />
          Portfolio
        </h4>
        <h1 data-aos="fade-up">
          Featured <span style={{ color: "#28e98c" }}>Projects</span>
        </h1>
        <ProjectList>
          <ProjectItem data-aos="fade-up">
            <ProjectInner>
              <img src={proj1} alt="" />
              <TagsWrapper>
                {["ReactJs", "Web Development"].map((tag, idx) => (
                  <Tags key={idx}>{tag}</Tags>
                ))}
              </TagsWrapper>
            </ProjectInner>
            <h2>Global Furnika</h2>
          </ProjectItem>
          <ProjectItemFlex>
            <ProjectItem data-aos="fade-right">
              <ProjectInner>
                <img src={proj2} alt="" />
                <TagsWrapper>
                  {["Flutter", "Go"].map((tag, idx) => (
                    <Tags key={idx}>{tag}</Tags>
                  ))}
                </TagsWrapper>
              </ProjectInner>
              <h2>ABuddy</h2>
            </ProjectItem>
            <ProjectItem data-aos="fade-left">
              <ProjectInner>
                <img src={proj3} alt="" />
                <TagsWrapper>
                  {["ReactJs", "Go"].map((tag, idx) => (
                    <Tags key={idx}>{tag}</Tags>
                  ))}
                </TagsWrapper>
              </ProjectInner>
              <h2>InternKuy</h2>
            </ProjectItem>
          </ProjectItemFlex>
          <ProjectItemFlex>
            <ProjectItem data-aos="fade-right">
              <ProjectInner>
                <img src={proj6} alt="" />
                <TagsWrapper>
                  {["Flutter", "Go"].map((tag, idx) => (
                    <Tags key={idx}>{tag}</Tags>
                  ))}
                </TagsWrapper>
              </ProjectInner>
              <h2>Agrocery</h2>
            </ProjectItem>
            <ProjectItem data-aos="fade-left">
              <ProjectInner>
                <img src={proj5} alt="" />
                <TagsWrapper>
                  {["NextJs", "Go"].map((tag, idx) => (
                    <Tags key={idx}>{tag}</Tags>
                  ))}
                </TagsWrapper>
              </ProjectInner>
              <h2>Kepoinn.com</h2>
            </ProjectItem>
          </ProjectItemFlex>
          <ProjectItem data-aos="fade-up">
            <ProjectInner>
              <img src={proj4} alt="" />
              <TagsWrapper>
                {["ReactJs", "Go"].map((tag, idx) => (
                  <Tags key={idx}>{tag}</Tags>
                ))}
              </TagsWrapper>
            </ProjectInner>
            <h2>AKM Event Tracker</h2>
          </ProjectItem>
          <ProjectItem data-aos="fade-up">
            <ProjectInner>
              <img src={proj7} alt="" />
              <TagsWrapper>
                {["ReactJs", "Go"].map((tag, idx) => (
                  <Tags key={idx}>{tag}</Tags>
                ))}
              </TagsWrapper>
            </ProjectInner>
            <h2>Abbasource</h2>
          </ProjectItem>
        </ProjectList>
      </AboutWrapper>
    </CustomContainer>
  </SectionWrapper>
);

export default Projects;
