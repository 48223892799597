import styled from "styled-components";

export const TestimonyItem = styled.div``;

export const TestimonyInner = styled.div`
  border: 2px solid #565656;
  padding: 50px 50px 43px 50px;
  border-radius: 30px;

  & > p {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 27px;
  }

  & > span {
    font-size: 12px;
    display: inline-block;
    text-transform: uppercase;
    color: #fff;
  }
`;

export const TestimonyAuthorFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 45px;

  & > img {
    width: 45px !important;
    height: 45px;
    border-radius: 50%;
  }
`;

export const TestimonyAuthorDetail = styled.div`
  display: flex;
  flex-direction: column;

  & > h3 {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 6px;
  }

  & > p {
    color: #999999;
    font-size: 13px;
    line-height: 1.3;
  }
`;

export const TestimonyFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  @media screen and (min-width: 1024px) {
    margin-top: 30px;
  }
`;

export const TestimonyFooterButton = styled.button`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 2px solid #565656;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
`;

export const CarouselWrapper = styled.div``;

export const TestimonyCount = styled.div`
  color: #666666;
  font-size: 13px;

  & > span {
    color: #fff;
  }
`;
