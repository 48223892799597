import styled from "styled-components";

export const ServiceItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ServiceItem = styled.div`
  border: 1px solid #565656;
  padding: 30px;
  transition: 0.3s;
  border-radius: 20px;
  position: relative;

  &:hover {
    border-color: #28e98c;
  }

  & > svg {
    position: absolute;
    top: 33px;
    right: 25px;
    transform: scale(1.5);
  }

  & > h2 {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 8px;
  }

  & > p {
    color: #999999;
    font-size: 14px;
    margin-bottom: 30px;
    line-height: 1.5;
  }

  & > span,
  & > a {
    font-size: 12px;
    text-transform: uppercase;
    display: inline-block;
    color: #fff;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  @media screen and (min-width: 1024px) {
    padding: 44px 48px 41px 48px;

    & > h2 {
      font-size: 24px;
    }

    & > svg {
      top: 45px;
      right: 50px;
      transform: scale(2);
    }
  }
`;
