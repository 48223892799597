import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  CarouselWrapper,
  TestimonyAuthorDetail,
  TestimonyAuthorFlex,
  TestimonyCount,
  TestimonyFooter,
  TestimonyFooterButton,
  TestimonyInner,
  TestimonyItem,
} from "../style";
import * as Fa from "react-icons/fa";
import { TestimonyData } from "./data";

class Index extends Component {
  state = {
    count: 1,
    slider: {},
  };

  setCount = (value) => {
    this.setState({ count: value });
  };

  render() {
    let { count, slider } = this.state;
    return (
      <CarouselWrapper data-aos="fade-up">
        <OwlCarousel ref={(slide) => (slider = slide)} margin={8} items={1}>
          {TestimonyData.map((testimony, index) => (
            <TestimonyItem onClick={this.click} key={index}>
              <TestimonyInner>
                <TestimonyAuthorFlex>
                  <img src={testimony["image"]} alt="" />
                  <TestimonyAuthorDetail>
                    <h3>{testimony["name"]}</h3>
                    <p>
                      {testimony["role"]} of{" "}
                      <span style={{ color: "#28e98c" }}>
                        {testimony["company"]}
                      </span>
                    </p>
                  </TestimonyAuthorDetail>
                </TestimonyAuthorFlex>
                <p>{testimony["message"]}</p>
                <span>{testimony["type"]}</span>
              </TestimonyInner>
            </TestimonyItem>
          ))}
        </OwlCarousel>
        <TestimonyFooter>
          <TestimonyFooterButton
            onClick={() => {
              slider.prev(500);
              console.log(slider);
            }}
          >
            <Fa.FaAngleLeft color="#fff" size={20} />
          </TestimonyFooterButton>
          <TestimonyCount>
            <span>{count}</span> / 3
          </TestimonyCount>
          <TestimonyFooterButton
            onClick={() => {
              if (count < 3) {
                slider.next(500);
                console.log(slider);
              }
            }}
          >
            <Fa.FaAngleRight color="#fff" size={20} />
          </TestimonyFooterButton>
        </TestimonyFooter>
      </CarouselWrapper>
    );
  }
}

export default Index;
