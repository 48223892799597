import React from "react";
import { CustomContainer, SectionWrapper } from "../introduction/style";
import { AboutWrapper } from "../about/style";
import * as Bs from "react-icons/bs";
import { ServiceItem, ServiceItems } from "./style";
import * as Hi from "react-icons/hi2";

const Specialization = (props) => (
  <SectionWrapper>
    <CustomContainer>
      <AboutWrapper>
        <h4 data-aos="fade-up">
          <Hi.HiOutlineSquare3Stack3D />
          Services
        </h4>
        <h1 data-aos="fade-up">
          My <span style={{ color: "#28e98c" }}>Specialization</span>
        </h1>
        <ServiceItems>
          <ServiceItem data-aos="fade-up">
            <Bs.BsBezier color="#28e98c" />
            <h2>Website Development</h2>
            <p>I build website and go live with ReactJs or NextJs</p>
            <span>12 Projects</span>
          </ServiceItem>
          <ServiceItem data-aos="fade-up">
            <Bs.BsCodeSlash color="#28e98c" />
            <h2>Mobile Development</h2>
            <p>I build mobile apps with Flutter</p>
            <span>5 Projects</span>
          </ServiceItem>
          <ServiceItem data-aos="fade-up">
            <Bs.BsKeyboard color="#28e98c" />
            <h2>Tutorial</h2>
            <p>
              Teach on you on basic web development/backend <br />
              with HTML/CSS or Golang
            </p>
            <span>On Demand</span>
          </ServiceItem>
          <ServiceItem data-aos="fade-up">
            <Bs.BsCameraVideo color="#28e98c" />
            <h2>Content Creator</h2>
            <p>
              I post my slicing UI video on TikTok <br /> and soon going to
              share how to make it
            </p>
            <span>11 Videos</span>
          </ServiceItem>
          <ServiceItem data-aos="fade-up">
            <Bs.BsMic color="#28e98c" />
            <h2>Podcasting</h2>
            <p>
              I'm a devoted Christian as well, and <br />
              you might want to give a visit to my podcast.
            </p>
            <a
              href="https://open.spotify.com/show/7IUUzQOLnQc8cCsj71Du8a"
              target="_blank"
              rel="noreferrer"
            >
              Check it out
            </a>
          </ServiceItem>
        </ServiceItems>
      </AboutWrapper>
    </CustomContainer>
  </SectionWrapper>
);

export default Specialization;
