import styled from "styled-components";

export const LeftSidebarContainer = styled.div`
  border: 1px solid #565656;
  padding: 30px;
  border-radius: 30px;
  margin-top: 20px;

  & > h2 {
    font-weight: 300;
    text-align: center;
    font-size: 21px;
    margin-bottom: 0.5rem;
  }

  & > p {
    font-size: 14px;
    color: #9999d3;
    text-align: center;
    margin-bottom: 25px;
  }

  & > img {
    width: 100%;
    max-width: 250px;
    margin: 20px auto 46px;
    border-radius: 30px;
    display: block;
    filter: grayscale(60%);
    max-height: 211px;
    object-fit: cover;
    object-position: top;
  }

  @media screen and (min-width: 1024px) {
    position: fixed;
    left: 20px;
    top: 50%;
    width: 100%;
    z-index: 10;
    max-width: 350px;
    transform: translate(0, -50%);
    margin-top: 0;
  }
`;

export const LeftSidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > h1 {
    font-size: 28px;
  }

  & > p {
    max-width: 110px;
    text-align: right;
    line-height: 1.4;
    font-size: 14px;
  }
`;

export const SocialMediaFlex = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
`;

export const SocialMediaCard = styled.a`
  border: 2px solid #565656;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-decoration: none;

  &:hover {
    border-color: #28e98c;

    & > svg {
      color: #28e98c !important;
    }
  }
`;

export const HireMeButton = styled.button`
  background-color: #28e98c;
  color: #000000;
  padding: 11px 58px 10px 58px;
  border-radius: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #28e98c;
  transition: 0.3s;
  width: 100%;
  gap: 8px;
  cursor: pointer;
  margin-top: 1.5rem;
  text-transform: uppercase;

  &:hover {
    background-color: transparent;
    color: #28e98c;

    & > a {
      color: #28e98c;
    }
  }

  & > a {
    font-size: 16px;
    text-decoration: none;
    color: #000000;
  }
`;
