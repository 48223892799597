export const ExperienceData = [
  {
    date: "2022 - Present",
    experiences: [
      {
        title: "Founder & Engineering",
        company: "Kepoinn",
        href: "https://kepoinn.com",
      },
      {
        title: "Backend Engineer",
        company: "Stockbit",
        href: "https://stockbit.com",
      },
    ],
  },
  {
    date: "2019 - 2022",
    experiences: [
      {
        title: "Founder & Engineering",
        company: "InternKuy",
        href: "https://internkuy.com",
      },
      {
        title: "Software Engineer Lead",
        company: "Abbasource",
        href: "https://abbasource.com",
      },
      {
        title: "Creator & Voiceover",
        company: "KristenTalks",
        href: "https://open.spotify.com/show/7IUUzQOLnQc8cCsj71Du8a",
      },
      {
        title: "Jr Backend Engineer",
        company: "Style Theory",
        href: "https://styletheory.co",
      },
      {
        title: "Backend Engineer",
        company: "Shopee",
        href: "https://shopee.co.id",
      },
    ],
  },
  {
    date: "2017 - 2019",
    experiences: [
      {
        title: "Bible Training",
        company: "Full Time Training Indonesia",
        href: "#",
      },
    ],
  },
  {
    date: "2013 - 2017",
    experiences: [
      {
        title: "Software Engineer Intern",
        company: "PT GDN Blibli.com",
        href: "https://blibli.com",
      },
      {
        title: "Bachelor Degree of Computer Science",
        company: "Binus",
        href: "https://binus.ac.id",
      },
    ],
    isEnd: true,
  },
];
