import styled from "styled-components";

export const AboutWrapper = styled.div`
  max-width: 770px;
  margin-left: auto;
  width: 100%;

  & > h1 {
    font-size: 38px;
    letter-spacing: -0.2px;
    line-height: 60px;
    font-weight: 300;
    margin-bottom: 35px;
  }

  & > p {
    max-width: 610px;
    color: #999999;
    font-size: 16px;
    line-height: 1.7;
    margin-bottom: 30px;
  }

  & > h3 {
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 60px;
  }

  & > h4 {
    display: inline-flex;
    margin-bottom: 53px;
    text-transform: uppercase;
    border: 1px solid #565656;
    padding: 9px 20px;
    border-radius: 30px;
    align-items: center;
    font-weight: 300;
    gap: 10px;
    font-size: 12px;
  }

  @media screen and (min-width: 1024px) {
    & > h1 {
      font-size: 48px;
    }
  }
`;
