import styled from "styled-components";

export const FormWrapper = styled.form``;

export const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 34px;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;

  & > label {
    text-transform: uppercase;
    font-size: 12px;
    color: #fff;
    margin-bottom: 2px;

    & > sup {
      color: #fc4545;
      font-size: 12px;
      top: 0;
    }
  }

  & > input,
  & > textarea {
    color: #fff;
    width: 100%;
    border: none;
    background: none;
    font-size: 18px;
    outline: none;
    padding: 0;
    transition: 0.2s;
    padding-bottom: 3px;
  }

  & > select {
    color: #fff;
    font-size: 18px;
    transition: 0.2s;
    margin-left: -4px;
    font-weight: 300;
    background: none;
    border: none;
    outline: none;
  }

  & > textarea {
    border-bottom: 1px solid #555555;
    height: 140px;
  }
`;

export const ButtonWrapper = styled.div``;
