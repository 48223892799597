import React from "react";
import About from "../../../section/v3/about";
import Advantages from "../../../section/v3/advantages";
import Contact from "../../../section/v3/contact";
import Courses from "../../../section/v3/courses";
import Experience from "../../../section/v3/experience";
import Introduction from "../../../section/v3/introduction";
import Pricing from "../../../section/v3/pricing";
import Projects from "../../../section/v3/projects";
import Specialization from "../../../section/v3/spesialization";
import Testimony from "../../../section/v3/testimony";

const MainPage = (props) => (
  <>
    <Introduction />
    <Courses />
    <About />
    <Experience />
    <Specialization />
    <Advantages />
    <Projects />
    <Testimony />
    <Pricing />
    <Contact />
  </>
);

export default MainPage;
