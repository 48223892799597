import React from "react";
import {
  HireMeButton,
  LeftSidebarContainer,
  LeftSidebarHeader,
  SocialMediaCard,
  SocialMediaFlex,
} from "./style";
import logo from "../../../assets/images/logo.png";
import * as Fa from "react-icons/fa";

const KC = require("../../../assets/images/kc3.png");

const LeftSidebar = (props) => (
  <LeftSidebarContainer>
    <LeftSidebarHeader>
      <img src={logo} alt="" />
      <p>Software Engineer</p>
    </LeftSidebarHeader>
    <img src={KC.default} alt="" />
    <h2>Kevin Christian</h2>
    <h2 style={{ marginBottom: "30px" }}>Base in Jakarta, Indonesia</h2>
    <p>© 2023 ByKace. All Rights Reserved</p>
    <SocialMediaFlex>
      <SocialMediaCard
        href="https://tiktok.com/@katdje"
        target="_blank"
        rel="noreferrer"
      >
        <Fa.FaTiktok color="#999999" />
      </SocialMediaCard>
      <SocialMediaCard
        href="https://github.com/keyvnchristian"
        target="_blank"
        rel="noreferrer"
      >
        <Fa.FaGithub color="#999999" />
      </SocialMediaCard>
      <SocialMediaCard
        href="https://www.linkedin.com/in/nkevinch/"
        target="_blank"
        rel="noreferrer"
      >
        <Fa.FaLinkedinIn color="#999999" />
      </SocialMediaCard>
      <SocialMediaCard
        href="https://www.instagram.com/kvynchristian/"
        target="_blank"
        rel="noreferrer"
      >
        <Fa.FaInstagram color="#999999" />
      </SocialMediaCard>
    </SocialMediaFlex>
    <HireMeButton>
      <Fa.FaRegEnvelope />
      <a href="mailto:nkevinch@gmail.com?subject=I Want to Hire you">Hire Me</a>
    </HireMeButton>
  </LeftSidebarContainer>
);

export default LeftSidebar;
