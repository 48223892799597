import React from "react";
import { CustomContainer, SectionWrapper } from "../introduction/style";
import { AboutWrapper } from "../about/style";
import {
  PricingFeatures,
  PricingFlex,
  PricingFooter,
  PricingHeader,
  PricingItem,
  PricingItems,
  PricingTable,
  PricingTop,
} from "./style";
import { PricingData } from "./data";
import * as Tb from "react-icons/tb";

const Pricing = (props) => (
  <SectionWrapper>
    <CustomContainer>
      <AboutWrapper>
        <h4 data-aos="fade-up">
          <Tb.TbCurrencyDollar />
          Pricing
        </h4>
        <h1 data-aos="fade-up">
          My <span style={{ color: "#28e98c" }}>Pricing</span>
        </h1>
        <PricingItems>
          <PricingFlex>
            {PricingData.map((pricing, index) => (
              <PricingItem key={index} data-aos={pricing["aos"]}>
                <PricingTable>
                  <PricingHeader>
                    <PricingTop>
                      <h4>{pricing["title"]}</h4>
                      <p>{pricing["desc"]}</p>
                    </PricingTop>
                    <h1>
                      {pricing["amount"]} <span>/ hour</span>
                    </h1>
                  </PricingHeader>
                  <PricingFeatures>
                    {pricing["features"].map((feature, idx) => (
                      <span key={idx}>{feature}</span>
                    ))}
                  </PricingFeatures>
                  <a
                    href={`mailto:nkevinch@gmail.com?subject=Hiring for ${pricing["title"]} Package`}
                  >
                    Pick this package
                  </a>
                </PricingTable>
              </PricingItem>
            ))}
          </PricingFlex>
        </PricingItems>
        <PricingFooter data-aos="fade-up">
          Don't find any package match with your plan? <br />
          Want to setup a new tailor-made package for only you?{" "}
          <a href="mailto:nkevinch@gmail.com">Contact Me</a>
        </PricingFooter>
      </AboutWrapper>
    </CustomContainer>
  </SectionWrapper>
);

export default Pricing;
