export const TestimonyData = [
  {
    image:
      "https://media.licdn.com/dms/image/C5603AQGziHUoAUW3zA/profile-displayphoto-shrink_100_100/0/1614157203427?e=1689206400&v=beta&t=1pPRddgQfAsQ2TZMC2-JApZpvDGAMX2e4uVhiiSHvDU",
    name: "Kriswira S.S",
    role: "Owner",
    company: "KConstructionTeam",
    message:
      "Strong multi tasking, programming, and creativity skill, manage many project with superb result. Also a great friend to have with in your life. Cheers 🍻",
    type: "Work Colleague",
  },
  {
    image:
      "https://media.licdn.com/dms/image/D5603AQHtUQJgJgbjZA/profile-displayphoto-shrink_100_100/0/1672285145080?e=1689206400&v=beta&t=Q9b_KECaP4e9O8bc9ntpW_JWULD8qiANsUexZAHxhII",
    name: "Raynor M.",
    role: "Frontend",
    company: "Kompas Gramedia",
    message:
      " Super excellent in programming and making others understand programming as well.",
    type: "Report Directly to Me",
  },
  {
    image:
      "https://shreethemes.in/jobstack/layouts/assets/images/about/ab02.jpg",
    name: "Nany M.",
    role: "COO",
    company: "Abbasource",
    message:
      "Kevin is a Developer with the creativity, professional and  master of code. Much more than what i'm expect. High quality product; good leadership. Recommended!",
    type: "Projects",
  },
];
