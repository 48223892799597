import React from "react";
import { CustomContainer, SectionWrapper } from "../introduction/style";
import { AboutWrapper } from "./style";
import * as Rx from "react-icons/rx";

const About = () => (
  <SectionWrapper>
    <CustomContainer>
      <AboutWrapper>
        <h4 data-aos="fade-up">
          <Rx.RxPerson />
          About
        </h4>
        <h1 data-aos="fade-up">
          Every great design begin with an even
          <span style={{ color: "#28e98c" }}> better story</span>
        </h1>
        <p data-aos="fade-up">
          I'm a Software Engineer, a Product and Education enthusiast. I help
          young people to thrive on Engineering things as well as on knowing and
          loving God ❤️. Since beginning my journey as a Software Engineer
          nearly 3 years ago, I've done remote work for companies, worked for
          startups, and collaborated with talented people to create digital
          products for both business and consumer use. I'm quietly confident,
          naturally curious, and perpetually working on improving my system
          design skills at a time.
        </p>
      </AboutWrapper>
    </CustomContainer>
  </SectionWrapper>
);

export default About;
