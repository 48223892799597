import React from "react";
import styled from "styled-components";
import video1 from "../../../assets/video/video1.mp4";

const VideoWrapper = styled.video`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  opacity: 0.3;
`;

const Video = () => (
  <VideoWrapper autoPlay loop muted playsInline>
    <source src={video1} type="video/mp4" />
  </VideoWrapper>
);

export default Video;
