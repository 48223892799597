import styled from "styled-components";

export const ProjectList = styled.div``;

export const ProjectItem = styled.div`
  margin-bottom: 30px;

  & > h2 {
    color: #fff;
    font-size: 21px;
    font-weight: 300;
    display: inline-block;

    &:hover {
      padding-bottom: 2px;
      border-bottom: 1px solid #fff;
    }
  }
`;

export const ProjectInner = styled.div`
  border-radius: 30px;
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  height: 370px;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const TagsWrapper = styled.div`
  position: absolute;
  display: flex;
  gap: 10px;
  bottom: 20px;
  left: 20px;
`;

export const Tags = styled.div`
  background: #fff;
  color: #000;
  padding: 7px 20px;
  border-radius: 19px;
  font-size: 14px;
  transition: 0.3s;
`;

export const ProjectItemFlex = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    gap: 16px;

    & > div {
      width: 50%;
    }
  }
`;
