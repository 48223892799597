import React from "react";
import { CustomContainer, SectionWrapper } from "../introduction/style";
import { AboutWrapper } from "../about/style";
import Carousel from "./carousel";
import * as Tfi from "react-icons/tfi";

const Testimony = (props) => {
  return (
    <SectionWrapper>
      <CustomContainer>
        <AboutWrapper>
          <h4 data-aos="fade-up">
            <Tfi.TfiCommentAlt />
            Testimonial
          </h4>
          <h1 data-aos="fade-up">
            Trusted by <span style={{ color: "#28e98c" }}>Them</span>
          </h1>
          <Carousel />
        </AboutWrapper>
      </CustomContainer>
    </SectionWrapper>
  );
};

export default Testimony;
