import React from "react";
import {
  ContentWrapper,
  CustomContainer,
  Experience,
  ExperienceFlex,
  GoToProject,
  SectionWrapper,
} from "./style";
import round from "../../../assets/images/v3/landing/round.png";
import * as Fi from "react-icons/fi";

const Introduction = (props) => {
  return (
    <SectionWrapper>
      <CustomContainer>
        <ContentWrapper>
          <h4>
            <Fi.FiHome />
            Introduction
          </h4>
          <h1 data-aos="fade-up">
            Hello from <span style={{ color: "#28e98c" }}>KC</span>, Software
            Engineer.
          </h1>
          <p data-aos="fade-up">
            I design and code beautifully simple things, stands on a sweet pot
            where design and code intersects. Just simple like that!
          </p>
          <GoToProject>
            <a href="#project">
              <img src={round} alt="" />
              <Fi.FiArrowDown color="#fff" size={40} />
            </a>
          </GoToProject>
          <ExperienceFlex>
            <Experience data-aos="fade-right">
              <h1>3+</h1>
              <p>
                Years of <br />
                Experience
              </p>
            </Experience>
            <Experience data-aos="fade-left">
              <h1>10+</h1>
              <p>
                Project Completed <br />
                From Clients
              </p>
            </Experience>
          </ExperienceFlex>
        </ContentWrapper>
      </CustomContainer>
    </SectionWrapper>
  );
};

export default Introduction;
