import styled, { keyframes } from "styled-components";

export const SectionWrapper = styled.section`
  padding: 68px 0;
  position: relative;
  overflow: hidden;
`;

export const CustomContainer = styled.div`
  max-width: 1130px;
  margin: auto;
  padding: 0 15px;
`;

export const ContentWrapper = styled.div`
  max-width: 770px;
  margin-left: auto;
  width: 100%;

  & > h1 {
    font-size: 50px;
    letter-spacing: -0.2px;
    line-height: 1.3;
    font-weight: 300;
    margin-bottom: 35px;
  }

  & > p {
    max-width: 480px;
    color: #9999d3;
    font-size: 16px;
    line-height: 1.7;
    margin-bottom: 30px;
  }

  & > h4 {
    display: inline-flex;
    margin-bottom: 53px;
    text-transform: uppercase;
    border: 1px solid #565656;
    padding: 9px 20px;
    border-radius: 30px;
    align-items: center;
    font-weight: 300;
    gap: 10px;
    font-size: 12px;
  }

  @media screen and (min-width: 1024px) {
    & > h1 {
      font-size: 78px;
      line-height: 90px;
    }
  }
`;

const spinningAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const GoToProject = styled.div`
  display: flex;

  & > a {
    border: 1px solid #575757;
    border-radius: 50%;
    width: 175px;
    height: 175px;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    & > img {
      max-width: 100%;
      animation: ${spinningAnimation} 6s infinite linear;
      vertical-align: middle;
    }

    & > svg {
      position: absolute;
    }
  }

  @media screen and (min-width: 1024px) {
    justify-content: flex-end;
  }
`;

export const ExperienceFlex = styled.div`
  display: flex;
  gap: 100px;
  margin-top: 50px;
`;

export const Experience = styled.div`
  & > h1 {
    color: #28e98c;
    line-height: 55px;
    font-size: 72px;
    margin-bottom: 32px;
  }

  & > p {
    color: #999999;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
  }
`;
