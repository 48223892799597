import { createGlobalStyle } from "styled-components";

import ProximaNovaBoldWoff from "../../assets/fonts/proxima/ProximaNova-Bold.woff";
import ProximaNovaBoldWoff2 from "../../assets/fonts/proxima/ProximaNova-Bold.woff2";
import ProximaNovaRegularWoff1 from "../../assets/fonts/proxima/ProximaNova-Regular.woff";
import ProximaNovaRegularWoff2 from "../../assets/fonts/proxima/ProximaNova-Regular.woff2";
import ProximaNovaThinWoff from "../../assets/fonts/proxima/ProximaNova-Thin.woff";
import ProximaNovaThinWoff2 from "../../assets/fonts/proxima/ProximaNova-Thin.woff2";

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Proxima Nova';
    src: url('${ProximaNovaRegularWoff2}') format('woff2'),
    url('${ProximaNovaRegularWoff1}') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Proxima Nova';
    src: url('${ProximaNovaThinWoff2}') format('woff2'),
    url('${ProximaNovaThinWoff}') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }


  @font-face {
    font-family: 'Proxima Nova';
    src: url('${ProximaNovaBoldWoff2}') format('woff2'),
    url('${ProximaNovaBoldWoff}') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  * {
    margin: 0;
    font-family: 'Proxima Nova', sans-serif;
  }

  body {
    background-color: #1f1f1f;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1, h2, h3, h4, h5, h6, p {
    color: #fff;
    margin: 0;
  }
`;
