import React from "react";
import { CustomContainer, SectionWrapper } from "../introduction/style";
import { AboutWrapper } from "../about/style";
import Work from "../../../components/v3/form/work";
import * as Tb from "react-icons/tb";

const Contact = (props) => (
  <SectionWrapper>
    <CustomContainer>
      <AboutWrapper>
        <h4 data-aos="fade-up">
          <Tb.TbMail />
          Contact
        </h4>
        <h1 data-aos="fade-up">
          Let's Work <span style={{ color: "#28e98c" }}>Together</span>
        </h1>
        <h3 data-aos="fade-up">nkevinch@gmail.com</h3>
        <Work />
      </AboutWrapper>
    </CustomContainer>
  </SectionWrapper>
);

export default Contact;
